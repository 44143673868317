import { useInViewport } from "ahooks"
import { useEffect, useRef } from "react"
import { useCallbackOncePerPage } from "src/hooks/useCallbackOncePerPage"

export type FunctionRef = (node?: Element | null) => void

export function useInViewEffectOncePerPage({
  id,
  effect,
  dependencies,
}: {
  id: string
  effect: () => void
  dependencies?: unknown[]
}) {
  const ref = useRef<any>(null)
  const [inView] = useInViewport(ref)

  const callback = useCallbackOncePerPage({
    id,
    condition: !!inView,
    effect,
    dependencies,
  })

  useEffect(() => callback(), [callback])

  return ref
}
