import { action_getCachedRegionByCountryCode } from "src/components/(checkout)/cart/actions"
import { enrichLineItems } from "src/components/(checkout)/cart/enrich"
import { queueCartUpdate } from "src/components/(checkout)/cart/state"
import {
  addItem,
  createCart,
  deleteDiscount,
  removeItem,
  retrieveCart,
  setShippingMethod,
  updateCart,
  updateItem,
} from "src/data/medusa/dynamic/carts"
import { getCountryCodeOrThrow } from "src/localization/state"
import {
  trackAddShippingInfo,
  trackAddToCart,
  trackCouponApplied,
  trackGiftCardApplied,
  trackRemoveFromToCart,
} from "src/tracking/api"
import { MedusaAddress, MedusaAddressPayload, MedusaCart } from "src/types/medusa"
import { StorefrontCart } from "src/types/storefront"

function resetAddress(oldAddress: MedusaAddress | null | undefined, countryCode: string) {
  return {
    company: oldAddress?.company || undefined,
    first_name: oldAddress?.first_name || undefined,
    last_name: oldAddress?.last_name || undefined,
    phone: oldAddress?.phone || undefined,
    metadata: oldAddress?.metadata,
    address_1: "",
    address_2: "",
    postal_code: "",
    city: "",
    province: "",
    country_code: countryCode,
  } satisfies MedusaAddressPayload
}

export async function restoreOrCreateCart({
  cartId,
  ensureExists = true,
}: {
  cartId: string | null
  ensureExists: boolean
}) {
  let cart: MedusaCart | null = null

  if (cartId) {
    cart = await retrieveCart(cartId)
  }

  if (!cart && !ensureExists) {
    return null
  }

  const countryCode = getCountryCodeOrThrow()
  const region = await action_getCachedRegionByCountryCode(countryCode)

  if (!region) {
    throw new Error(`Failed to find region for country code: ${countryCode}`)
  }

  if (cart) {
    if (cart.region_id !== region.id) {
      console.debug(`🛒 Updating cart region from ${cart.region.name} to ${region.name} (${countryCode})`)
      cart = await updateCart(cart.id, {
        region_id: region.id,
        country_code: countryCode,
        shipping_address: resetAddress(cart.shipping_address, countryCode),
        billing_address: resetAddress(cart.billing_address, countryCode),
      })
    }
  } else {
    cart = await createCart({
      region_id: region.id,
      country_code: countryCode,
    })
  }

  if (!cart) {
    throw new Error("Failed to restore or create cart")
  }

  return cart
}

export async function queueAddItemToCart({
  variantId,
  quantity,
}: {
  variantId: string
  quantity: number
}) {
  const newCart = await queueCartUpdate((cart) => {
    const cartId = cart.id
    return addItem({ cartId, variantId, quantity })
  })
  const newItem = newCart?.items.find((item) => item.variant.id === variantId)
  const quantityChange = 1

  if (newCart && newItem) {
    trackAddToCart(newCart, newItem, quantityChange)
  }

  return newCart
}

export async function queueRemoveItemFromCart({
  oldCart,
  lineId,
}: {
  oldCart: StorefrontCart
  lineId: string
}) {
  const oldItem = oldCart.items.find((item) => item.id === lineId)

  const newCart = await queueCartUpdate((cart) => {
    const cartId = cart.id
    return removeItem({ cartId, lineId })
  })

  if (newCart && oldItem) {
    const quantityChange = oldItem.quantity

    trackRemoveFromToCart(newCart, oldItem, quantityChange)
  }

  return newCart
}

export async function queueEditCartItemQuantity({
  oldCart,
  lineId,
  targetQuantity,
}: {
  oldCart: StorefrontCart
  lineId: string
  targetQuantity: number
}) {
  const oldItem = oldCart.items.find((item) => item.id === lineId)
  const quantityChange = targetQuantity - (oldItem?.quantity || 0)

  const newCart = await queueCartUpdate((cart) => {
    const cartId = cart.id
    return updateItem({ cartId, lineId, quantity: targetQuantity })
  })
  const newItem = newCart?.items.find((item) => item.id === lineId)

  if (newCart && newItem) {
    if (quantityChange > 0) {
      trackAddToCart(newCart, newItem, quantityChange)
    } else {
      trackRemoveFromToCart(newCart, newItem, -quantityChange)
    }
  }

  return newCart
}

export async function queueApplyDiscount(code: string) {
  const newCart = await queueCartUpdate((cart) => {
    return updateCart(cart.id, { discounts: [{ code }] })
  })

  trackCouponApplied(code)

  return newCart
}

export async function queueRemoveDiscount(code: string) {
  return queueCartUpdate((cart) => {
    const cartId = cart.id
    return deleteDiscount({ cartId, code })
  })
}

export async function queueApplyGiftCard(code: string) {
  const newCart = await queueCartUpdate((cart) => {
    return updateCart(cart.id, {
      gift_cards: cart.gift_cards
        .filter((giftCart) => giftCart.code !== code)
        .map((giftCard) => ({ code: giftCard.code }))
        .concat({ code }),
    })
  })

  trackGiftCardApplied(code)

  return newCart
}

export async function queueRemoveGiftCard(code: string) {
  const newCart = await queueCartUpdate((cart) => {
    return updateCart(cart.id, {
      gift_cards: cart.gift_cards
        .filter((giftCart) => giftCart.code !== code)
        .map((giftCard) => ({ code: giftCard.code })),
    })
  })

  return newCart
}

export async function queueUpdateShippingAddress(shippingMethodId: string) {
  const newCart = await queueCartUpdate(async (cart) => {
    return setShippingMethod({
      cartId: cart.id,
      shippingMethodId,
    })
  })

  trackAddShippingInfo(newCart)

  return newCart
}
