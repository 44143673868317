import { ccm19GetAcceptedEmbeddings } from "src/integrations/ccm19/api"
import { Ccm19EmbeddingName } from "src/integrations/ccm19/embeddings"
import { updateUserData } from "src/integrations/lib/userData"
import { getUserId } from "src/tracking/state"
import { googleConfig } from "./config"
import { dataLayerPush } from "./dataLayer"

export function configureGoogleDefaultConsent() {
  const acceptedEmbeddings = ccm19GetAcceptedEmbeddings()
  const marketing = acceptedEmbeddings.some(
    (embedding) =>
      embedding.name === Ccm19EmbeddingName.GoogleAdsConversion ||
      embedding.name === Ccm19EmbeddingName.GoogleAdsRemarketing,
  )
  const statistics = acceptedEmbeddings.some((embedding) => embedding.name === Ccm19EmbeddingName.GoogleAnalytics)
  const preferences = statistics
  const necessary = statistics

  dataLayerPush("consent", "default", {
    ad_personalization: marketing ? "granted" : "denied",
    ad_storage: marketing ? "granted" : "denied",
    ad_user_data: marketing ? "granted" : "denied",
    analytics_storage: statistics ? "granted" : "denied",
    functionality_storage: preferences ? "granted" : "denied",
    personalization_storage: preferences ? "granted" : "denied",
    security_storage: necessary ? "granted" : "denied",
    wait_for_update: false,
  })

  dataLayerPush("set", "ads_data_redaction", !marketing)
  dataLayerPush("set", "url_passthrough", false)

  updateUserData()
}

function getConfig() {
  return {
    server_container_url: googleConfig.taggingServerOrigin,
    first_party_collection: true,
    allow_enhanced_conversions: true,
    send_page_view: false,
    user_id: getUserId(),
  }
}

export function configureGoogleTag(overrides: Record<string, any> = {}) {
  dataLayerPush("js", new Date())
  dataLayerPush("config", googleConfig.measurementId, { ...getConfig(), ...overrides })
}
