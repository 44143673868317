import { action_getProductsByIds } from "src/components/(checkout)/cart/actions"
import { MedusaLineItem } from "src/types/medusa"
import { StorefrontLineItem } from "src/types/storefront"
import { addErrorContext, reportError } from "src/utilities/error"

function compareLineItems(a: StorefrontLineItem, b: StorefrontLineItem) {
  return a.created_at > b.created_at ? 1 : -1
}

export async function enrichLineItemsUsingPreviousData<
  NewData extends { items: MedusaLineItem[] },
  OldData extends { items: StorefrontLineItem[] },
>(newData: NewData, oldData: OldData | null) {
  const previousItemsById = (oldData?.items || []).reduce<Record<string, StorefrontLineItem>>((result, item) => {
    result[item.id] = item
    return result
  }, {})

  const missingProductIds = newData.items
    .filter((item) => !previousItemsById[item.id])
    .flatMap((item) => {
      const productId = item.variant.product_id

      if (!productId) {
        addErrorContext("item", item)
        reportError("Product id not found for item: " + JSON.stringify(item))
        return []
      }

      return productId
    })

  const products = missingProductIds.length ? await action_getProductsByIds(missingProductIds) : []

  const newItems = newData.items.map((item) => {
    const previousItem = previousItemsById?.[item.id]

    const product = previousItem?.product || products.find((product) => product.id === item.variant.product_id)

    if (!product) {
      throw new Error(`Product not found for item ${item.id}`)
    }

    const variant = previousItem?.variant || product?.variants?.find((variant) => variant.id === item.variant_id)

    if (!variant) {
      throw new Error(`Variant not found for item ${item.id}`)
    }

    const storefrontItem: StorefrontLineItem = {
      ...item,
      product: product,
      variant: variant,
    }

    return storefrontItem
  })

  newItems.sort(compareLineItems)

  return {
    ...newData,
    items: newItems,
  }
}

export async function enrichLineItems<Data extends { items: MedusaLineItem[] }>(data: Data) {
  return enrichLineItemsUsingPreviousData(data, null)
}
