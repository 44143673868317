import { configureGoogleTag } from "./configureGoogleTag"
import { dataLayerPush, dataLayerPushEvent } from "./dataLayer"
import {
  GoogleAddPaymentInfoEvent,
  GoogleAddShippingInfoEvent,
  GoogleAddToCartEvent,
  GoogleBeginCheckoutEvent,
  GoogleEmbedEvent,
  GoogleFormEvent,
  GooglePurchaseEvent,
  GoogleRemoveFromCartEvent,
  GoogleSelectItemEvent,
  GoogleSelectPromotionEvent,
  GoogleViewCartEvent,
  GoogleViewItemEvent,
  GoogleViewItemListEvent,
  GoogleViewPromotionEvent,
} from "./types"

export function googleTrackPageView({ referrer }: { referrer: string }) {
  const page_location = window.location.href
  const page_path = window.location.pathname

  // https://developers.google.com/analytics/devguides/collection/ga4/single-page-applications?implementation=event#custom_event_implementation
  configureGoogleTag({
    update: true,
    page_referrer: referrer,
    page_path,
    page_location,
  })
  dataLayerPush("set", "page_path", page_path)
  dataLayerPushEvent("page_view", {
    page_referrer: referrer,
    page_location,
  })
}

export function googleTrackSearch({ search_term }: { search_term: string }) {
  const searchTermCleaned = search_term.trim().toLowerCase()

  if (!searchTermCleaned) {
    return
  }

  // https://support.google.com/analytics/answer/9234069?hl=en&ref_topic=13367566
  dataLayerPushEvent("search", { search_term: searchTermCleaned })
}

export function googleTrackFormView(data: GoogleFormEvent) {
  dataLayerPushEvent("form_view", data)
}

export function googleTrackFormSubmit(data: GoogleFormEvent) {
  dataLayerPushEvent("form_submit", data)
}

export function googleTrackFormStepSubmit(data: GoogleFormEvent) {
  dataLayerPushEvent("form_step_submit", data)
}

export function googleTrackFormUrlRedirect(data: GoogleFormEvent) {
  dataLayerPushEvent("form_url_redirect", data)
}

export function googleTrackFormClose(data: GoogleFormEvent) {
  dataLayerPushEvent("form_close", data)
}

export function googleTrackEmbedView(data: GoogleEmbedEvent) {
  dataLayerPushEvent("embed_view", data)
}

export function googleTrackViewItemList(data: GoogleViewItemListEvent) {
  dataLayerPushEvent("view_item_list", data)
}

export function googleTrackViewItem(data: GoogleViewItemEvent) {
  dataLayerPushEvent("view_item", data)
}

export function googleTrackViewPromotion(data: GoogleViewPromotionEvent) {
  dataLayerPushEvent("view_promotion", data)
}

export function googleTrackSelectItem(data: GoogleSelectItemEvent) {
  dataLayerPushEvent("select_item", data)
}

export function googleTrackSelectPromotion(data: GoogleSelectPromotionEvent) {
  dataLayerPushEvent("select_promotion", data)
}

export function googleTrackCouponApplied(code: string) {
  dataLayerPushEvent("coupon_applied", { code: code.toLowerCase() })
}

export function googleTrackGiftCardApplied(code: string) {
  dataLayerPushEvent("gift_card_applied", { code: code.toLowerCase() })
}

export function googleTrackViewCart(data: GoogleViewCartEvent) {
  dataLayerPushEvent("view_cart", data)
}

export function googleTrackAddToCart(data: GoogleAddToCartEvent) {
  dataLayerPushEvent("add_to_cart", data)
}

export function googleTrackRemoveFromCart(data: GoogleRemoveFromCartEvent) {
  dataLayerPushEvent("remove_from_cart", data)
}

export function googleTrackBeginCheckout(data: GoogleBeginCheckoutEvent) {
  dataLayerPushEvent("begin_checkout", data)
}

export function googleTrackAddShippingInfo(data: GoogleAddShippingInfoEvent) {
  dataLayerPushEvent("add_shipping_info", data)
}

export function googleTrackAddPaymentInfo(data: GoogleAddPaymentInfoEvent) {
  dataLayerPushEvent("add_payment_info", data)
}

export function googleTrackPurchase(data: GooglePurchaseEvent) {
  dataLayerPushEvent("purchase", {
    ...data,
    event_id: data.transaction_id,
  })
}
