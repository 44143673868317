import { useEffect } from "react"
import { useCallbackOncePerPage } from "./useCallbackOncePerPage"

export function useEffectOncePerPage(options: {
  id: string
  condition: boolean
  effect: () => void
  dependencies?: unknown[]
}) {
  const callback = useCallbackOncePerPage(options)

  useEffect(() => callback(), [callback])
}
