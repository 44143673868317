import { useCallback, useEffect } from "react"
import { useWindowLocationPathname } from "src/hooks/useWindowLocationPathname"

// This must be a shared object and not a usePrevious() call because we want to
// limit actions to once per page, not once per component.
const memory = new Map<string, string>() // <id, path_previously_run_on>

export function useCallbackOncePerPage({
  id,
  condition,
  effect,
  dependencies = [],
}: {
  id: string
  condition: boolean
  effect: () => void
  dependencies?: unknown[]
}) {
  const pathname = useWindowLocationPathname()

  // When navigating, free up the effect.
  useEffect(() => {
    const previousPathname = memory.get(id)

    if (previousPathname === pathname) {
      return
    }

    memory.delete(id)
  }, [id, pathname, ...dependencies])

  return useCallback(() => {
    const previousPathname = memory.get(id)

    if (previousPathname === pathname || !condition) {
      return
    }

    effect()

    memory.set(id, pathname)
  }, [id, condition, effect, pathname, ...dependencies])
}
