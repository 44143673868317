import { StorefrontCart } from "src/types/storefront"

export function createCartHash(cart: StorefrontCart | null | undefined) {
  return [
    cart?.id,
    cart?.region_id,
    cart?.total,
    cart?.items.map((item) => item.id + "x" + item.quantity, "").join("|") || "",
    cart?.discounts.map((discount) => discount.id).join("|") || "",
    cart?.gift_cards.map((giftCard) => giftCard.id).join("|") || "",
  ]
    .filter(Boolean)
    .join("|")
}
