import { Customer, Order } from "@medusajs/medusa"
import { useEffect } from "react"
import { getSession } from "src/data/medusa/dynamic/auth"
import { listCustomerOrders } from "src/data/medusa/dynamic/customers"
import { useIsClient } from "src/hooks/useIsClient"
import { mergeContactDataFromObjects } from "src/integrations/lib/mergeState"
import { addErrorContext, reportError } from "src/utilities/error"
import { getPublicMedusaClient } from "src/utilities/medusa"
import { create as createStore } from "zustand"

export type ClientCustomer = Omit<Customer, "password_hash">

type State = {
  initializingCustomer?: boolean
  customer: ClientCustomer | null
  initializingOrders?: boolean
  orders: Order[]
}

const defaultState: State = {
  initializingCustomer: true,
  customer: null,
  initializingOrders: true,
  orders: [],
}

const store = createStore(() => defaultState)

export function setCustomer(customer: ClientCustomer | null) {
  store.setState({ customer, initializingCustomer: false })

  if (customer) {
    updateOrdersState()
  }

  mergeContactDataFromObjects({ customer })
}

export function getCustomer() {
  return store.getState().customer
}

export async function updateCustomerState() {
  try {
    store.setState({ customer: null, initializingCustomer: true })
    const customer = await getSession()
    setCustomer(customer)
  } catch (error) {
    // Don't log this error as it's expected when the user is not logged in.
    // console.error(error)
    setCustomer(null)
  }
}

export async function updateOrdersState() {
  try {
    store.setState({ orders: [], initializingOrders: true })
    const orders = await listCustomerOrders()
    store.setState({ orders, initializingOrders: false })
  } catch (error) {
    addErrorContext("customer", getCustomer())
    reportError("Failed to update customer orders", error)
    store.setState({ initializingOrders: false })
  }
}

let customerLoadTriggered = false

async function initializeCustomer() {
  if (!customerLoadTriggered) {
    customerLoadTriggered = true
    updateCustomerState()
  }
}

export function useIsCustomerInitializing() {
  const isClient = useIsClient()
  const initializing = store((state) => state.initializingCustomer)

  return !isClient || initializing
}

export function useIsOrdersInitializing() {
  const isClient = useIsClient()
  const initializing = store((state) => state.initializingOrders)

  return !isClient || initializing
}

export function useCustomer() {
  useEffect(() => {
    initializeCustomer()
  }, [])

  return store((state) => state.customer)
}

export function useOrders() {
  return store((state) => state.orders)
}

export async function signOut() {
  try {
    await getPublicMedusaClient().auth.deleteSession()
    store.setState({ customer: null, orders: [] })
  } catch (error) {
    console.error(error)
  }
}
