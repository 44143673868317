import { StorefrontOrder } from "src/types/storefront"

export function isOrderSucecssful(order: StorefrontOrder | undefined) {
  if (!order) {
    return false
  }

  const status = order.status

  return status === "pending" || status === "completed"
}
