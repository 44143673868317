import { isBrowser } from "src/utilities/env"

export const GA_MEASUREMENT_ID = "G-46N6MG427L"
export const GTM_CONTAINER_ID = "GTM-W2RB95T8"
export const GTM_SERVER_ORIGIN = "https://server-side-tagging-485352259680.europe-west3.run.app"

const isLocal = process.env.NODE_ENV === "development"
const taggingServerProxyPath = "/api/rewrites/sst"

function getTaggingServerOrigin() {
  if (isLocal) {
    return GTM_SERVER_ORIGIN
  }

  if (isBrowser) {
    return window.location.origin + taggingServerProxyPath
  }

  return taggingServerProxyPath
}

export const googleConfig = {
  taggingServerOrigin: getTaggingServerOrigin(),
  containerId: GTM_CONTAINER_ID,
  measurementId: GA_MEASUREMENT_ID,
}
