import { deburr } from "lodash"

export function latinize(text: string | undefined) {
  const latinized = (text || "")
    .replace(/Ä/g, "AE")
    .replace(/ä/g, "ae")
    .replace(/Ö/g, "OE")
    .replace(/ö/g, "oe")
    .replace(/Ü/g, "UE")
    .replace(/ü/g, "ue")
    .replace(/ß/g, "ss")

  return deburr(latinized)
}

export function slugify(text: string | undefined) {
  return latinize(text)
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-|-$/g, "")
}
