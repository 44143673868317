import { dialingPrefixByCountryCode } from "./phoneData"

/** https://en.wikipedia.org/wiki/E.164 */
export function formatPhoneNumberToE164(phone: string | null | undefined, countryCode: string) {
  if (!phone) {
    return
  }

  const dialingPrefix = dialingPrefixByCountryCode.get(countryCode)

  if (!dialingPrefix) {
    return phone.trim()
  }

  const phoneNumberNormalized = phone.replace(/\D/g, "")

  for (const prefix of [
    // wrap-line
    "+" + dialingPrefix + "0",
    "+" + dialingPrefix,
    dialingPrefix + "0",
    dialingPrefix,
    "0",
  ]) {
    if (phoneNumberNormalized.startsWith(prefix)) {
      return "+" + dialingPrefix + phoneNumberNormalized.substring(prefix.length)
    }
  }

  return "+" + dialingPrefix + phoneNumberNormalized
}
