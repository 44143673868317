"use client"

import { restoreOrCreateCart } from "src/components/(checkout)/cart/api"
import { enrichLineItemsUsingPreviousData } from "src/components/(checkout)/cart/enrich"
import { useIsClient } from "src/hooks/useIsClient"
import { mergeContactDataFromObjects } from "src/integrations/lib/mergeState"
import { MedusaCart } from "src/types/medusa"
import { StorefrontCart } from "src/types/storefront"
import { reportError } from "src/utilities/error"
import { createPersistentStore } from "src/utilities/localStorage/create"

export const CHECKOUT_STEPS = ["address", "delivery", "payment", "review"] as const

export type CheckoutStep = (typeof CHECKOUT_STEPS)[number]
export type CartUpdateJob = (cart: MedusaCart | StorefrontCart) => Promise<MedusaCart | null>

let cartUpdatePromise: Promise<StorefrontCart | null> | null = null

type State = {
  initializing: boolean
  cartId: string | null
  cart: StorefrontCart | null
}

const defaultState: State = {
  initializing: false,
  cartId: null,
  cart: null,
}

const store = createPersistentStore(() => defaultState, {
  name: "cart",
  partialize: (state) => ({
    cartId: state.cartId,
  }),
  onRehydrateStorage: () => {
    return () => {
      // The timeout avoids ReferenceError: Cannot access 'store' before initialization
      setTimeout(() => {
        cartUpdatePromise = restoreCart()
      }, 0)
    }
  },
})

async function restoreCart() {
  const cartId = store.getState().cartId

  if (cartId) {
    store.setState({ initializing: true })

    // Queueing an action will load the cart and apply the action. Since we
    // don't want to do anything, we queue null.
    return queueCartUpdate(null)
  }

  return null
}

function setCart(newCart: StorefrontCart | null) {
  if (newCart) {
    if (newCart.items[0] && !newCart.items[0].product) {
      throw new Error("The cart items are not enriched. Please call enrichLineItems.")
    }

    const oldCart = store.getState().cart

    // Cart mutations return a cart with less region info. We do this to preserve
    // the detailed region info available when creating the cart.
    if (oldCart?.region_id === newCart?.region_id) {
      newCart.region = oldCart?.region
    }
  }

  store.setState({
    cart: newCart,
    cartId: newCart?.id,
    initializing: false,
  })

  mergeContactDataFromObjects({ cart: newCart })
}

async function processCartUpdate(updateCart: CartUpdateJob | null) {
  const oldCartId = getCartId()
  const oldCart = getCart()

  let caughtError: unknown
  let newCartUnenriched: MedusaCart | null = null
  let newCart = oldCart
  let newCartId = oldCartId

  // If the cart has already been converted to an order, it can't be used
  // anymore. In that case, automatically create a new cart.
  if (oldCart?.completed_at) {
    newCartId = null
    newCart = null
    setCart(null)
  }

  if (!oldCart) {
    newCartUnenriched = await restoreOrCreateCart({ cartId: oldCartId, ensureExists: !!updateCart })
    newCartId = newCartUnenriched?.id || null

    if (newCartId !== oldCartId) {
      store.setState({ cartId: newCartId })
    }
  }

  if (updateCart) {
    try {
      const previousCart = (newCartUnenriched || oldCart)!
      const updatedCartUnenriched = await updateCart(previousCart)
      newCartUnenriched = updatedCartUnenriched || newCartUnenriched
    } catch (error) {
      caughtError = error
      reportError("Failed to process cart update job", error)
    }
  }

  cartUpdatePromise = null

  if (newCartUnenriched) {
    newCart = await enrichLineItemsUsingPreviousData(newCartUnenriched, oldCart)
    setCart(newCart)
  }

  if (caughtError) {
    throw caughtError
  }

  return newCart
}

export async function queueCartUpdate(updateCart: CartUpdateJob | null) {
  if (cartUpdatePromise) {
    await cartUpdatePromise.catch(() => null)
  }

  cartUpdatePromise = processCartUpdate(updateCart)

  return cartUpdatePromise
}

export async function queueCartUpdateOrThrow(job: CartUpdateJob) {
  const cart = await queueCartUpdate(job)

  if (!cart) {
    throw new Error("Failed to update cart")
  }

  return cart
}

export function getCartId() {
  return store.getState().cartId
}

export function getCartIdOrThrow() {
  const cartId = getCartId()

  if (!cartId) {
    throw new Error("No cartId found")
  }

  return cartId
}

export function getCart() {
  return store.getState().cart
}

export function getCartOrThrow() {
  const cart = getCart()

  if (!cart) {
    throw new Error("No cart found")
  }

  return cart
}

export function useCart() {
  return store((state) => state.cart)
}

export function useIsCartInitializing() {
  const isClient = useIsClient()
  const initializing = store((state) => state.initializing)

  return !isClient || initializing
}

export async function clearCart() {
  store.setState({ ...defaultState, initializing: false }, true)
}
