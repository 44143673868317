import { isBrowser } from "src/utilities/env"
import { create as createStore } from "zustand"

const store = createStore(() => ({
  pathname: isBrowser ? window.location.pathname : "/",
}))

function tick() {
  if (window.location.pathname !== store.getState().pathname) {
    store.setState({ pathname: window.location.pathname })
  }
}

if (isBrowser) {
  window.setInterval(tick, 10)
}

export function useWindowLocationPathname() {
  return store((state) => state.pathname)
}
