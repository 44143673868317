import { ClientCustomer, getCustomer } from "src/components/(account)/account/state"
import { getCart } from "src/components/(checkout)/cart/state"
import { getCountryCodeOrThrow } from "src/localization/state"
import { StorefrontCart, StorefrontOrder } from "src/types/storefront"
import { formatPhoneNumberToE164 } from "src/utilities/phoneNumber"
import { getContactData, mergeContactData } from "./state"

export function mergeContactDataFromObjects(data: {
  order?: StorefrontOrder | null
  cart?: StorefrontCart | null
  customer?: ClientCustomer | null
}) {
  let { order, cart, customer } = data

  if (!("cart" in data)) {
    cart = getCart()
  }
  if (!("customer" in data)) {
    customer = getCustomer()
  }

  const contactData = getContactData()

  const orderNumber = order?.display_id?.toString() || null
  const email = order?.email || cart?.email || customer?.email || contactData.email || null
  const address = order?.shipping_address?.first_name
    ? order.shipping_address
    : cart?.shipping_address?.first_name
      ? cart.shipping_address
      : customer?.shipping_addresses?.[0]
        ? customer.shipping_addresses[0]
        : contactData.address?.first_name
          ? contactData.address
          : null
  const phone = address?.phone || customer?.phone || contactData.phoneNormalized || null
  const countryCode = getCountryCodeOrThrow()
  const phoneNormalized = formatPhoneNumberToE164(phone, countryCode) || phone

  mergeContactData({
    email: email?.trim() || "",
    orderNumber: orderNumber?.trim() || "",
    phoneNormalized: phoneNormalized?.trim() || "",
    address: {
      first_name: address?.first_name?.trim() || "",
      last_name: address?.last_name?.trim() || "",
      phone: address?.phone?.trim() || "",
      metadata: address?.metadata,
      company: address?.company?.trim() || "",
      address_1: address?.address_1?.trim() || "",
      address_2: address?.address_2?.trim() || "",
      city: address?.city?.trim() || "",
      country_code: address?.country_code?.trim() || "",
      province: address?.province?.trim() || "",
      postal_code: address?.postal_code?.trim() || "",
    },
  })
}
