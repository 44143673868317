import {
  MedusaCreateCustomerAddressPayload,
  MedusaCreateCustomerPayload,
  MedusaUpdateCustomerAddressPayload,
  MedusaUpdateCustomerPayload,
} from "src/types/medusa"
import { getPublicMedusaClient, throwMedusaError } from "src/utilities/medusa"

export async function createCustomer(data: MedusaCreateCustomerPayload) {
  return getPublicMedusaClient()
    .customers.create(data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function updateCustomer(data: Partial<MedusaUpdateCustomerPayload>) {
  return getPublicMedusaClient()
    .customers.update(data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function addShippingAddress(data: MedusaCreateCustomerAddressPayload) {
  return getPublicMedusaClient()
    .customers.addresses.addAddress(data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function deleteShippingAddress(addressId: string) {
  return getPublicMedusaClient()
    .customers.addresses.deleteAddress(addressId)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function updateShippingAddress(addressId: string, data: MedusaUpdateCustomerAddressPayload) {
  return getPublicMedusaClient()
    .customers.addresses.updateAddress(addressId, data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export const listCustomerOrders = async (limit = 10, offset = 0) => {
  return getPublicMedusaClient()
    .customers.listOrders({ limit, offset })
    .then(({ orders }) => orders)
    .catch(throwMedusaError)
}
